<template>
  <v-card class="login-form-card" elevation="8">
    <!-- Logo and Header Section -->
    <div class="text-center pt-8 pb-4">
      <v-avatar size="180" class="mb-2">
              <v-img
                src="@/assets/icon_membership.png"
                alt="Membership Icon"
                contain
              ></v-img>
            </v-avatar>
      <div class="text-subtitle-1 grey--text">אזור אישי</div>
    </div>

    <!-- Login Form -->
    <v-form
      ref="form"
      v-model="valid"
      @submit.prevent="login"
      class="px-6 pb-6"
    >
      <v-card-text>
        <v-text-field
          v-model="username"
          :rules="usernameRules"
          label="שם משתמש"
          prepend-inner-icon="mdi-account"
          outlined
          dense
          required
          :loading="progressShow"
          :disabled="progressShow"
          color="primary"
          class="mb-2"
        ></v-text-field>

        <v-text-field
          v-model="password"
          :rules="passwordRules"
          label="סיסמה"
          prepend-inner-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          outlined
          dense
          required
          :loading="progressShow"
          :disabled="progressShow"
          color="primary"
        ></v-text-field>

        <!-- Forgot Password Link -->
        <div class="text-right mt-1">
          <v-btn
            text
            small
            color="primary"
            class="px-0"
            @click="forgotPassword"
          >
            שכחתי סיסמה
          </v-btn>
        </div>
      </v-card-text>

      <!-- Login Button -->
      <v-card-actions class="px-6 pt-2">
        <v-btn
          block
          x-large
          color="primary"
          :loading="progressShow"
          :disabled="!valid"
          elevation="2"
          @click="login"
          class="white--text font-weight-bold"
        >
          כניסה למערכת
        </v-btn>
      </v-card-actions>
    </v-form>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColorBt"
      :timeout="4000"
      top
      right
      elevation="24"
    >
      {{ snacbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          סגור
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import ApiServices from '@/services/api.service'

export default {
  name: 'FormLogin',
  
  data: () => ({
    valid: false,
    username: "",
    password: "",
    showPassword: false,
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "success",
    snacbarText: "",
    usernameRules: [
      v => !!v || 'שם משתמש הוא שדה חובה',
      v => v.length >= 3 || 'שם משתמש חייב להכיל לפחות 3 תווים'
    ],
    passwordRules: [
      v => !!v || 'סיסמה היא שדה חובה',
      v => v.length >= 6 || 'סיסמה חייבת להכיל לפחות 6 תווים'
    ]
  }),

  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        this.progressShow = true;
        
        try {
          const MyJSON = JSON.stringify({ 
            username: this.username, 
            password: this.password 
          });
          const api = process.env.VUE_APP_BASE_URL + "/auth/login";
          
          const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
          const jsonObject = await response.json();
          
          switch (response.status) {
            case 200:
              this.showSnackBar("התחברת בהצלחה!", "success");
              localStorage.setItem('token', jsonObject.token);
              this.$router.replace({ name: "home"});
              break;
            case 400:
              this.showSnackBar("שגיאת התחברות: " + jsonObject.message, "error");
              break;
            case 429:
              this.showSnackBar("יותר מדי נסיונות כניסה", "warning");
              break;
            default:
              this.showSnackBar("שגיאה לא צפויה", "error");
          }
        } catch (error) {
          this.showSnackBar("שגיאת התחברות: " + error, "error");
        } finally {
          this.progressShow = false;
        }
      }
    },

    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true;
    },

    forgotPassword() {
      // Implement forgot password functionality
      this.showSnackBar("בקרוב...", "info");
    }
  },

  mounted() {
    document.title = 'כניסה | משמחי לב';
  }
}
</script>

<style lang="scss" scoped>
.login-form-card {
  border-radius: 16px !important;
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
}

// RTL specific styles
:deep(.v-text-field__slot) {
  direction: rtl;
}

:deep(.v-label) {
  right: 0;
  left: auto;
}

:deep(.v-input__prepend-inner) {
  margin-right: 0;
  margin-left: 8px;
}

// Responsive adjustments
@media (max-width: 600px) {
  .login-form-card {
    margin: 16px;
  }
  
  :deep(.v-card__text) {
    padding: 16px !important;
  }
}
</style>