<template>
  <div class="home-container">
    <TablesList :tableListArray="tableListArray" />

    <div v-if="$route.name !== 'cashier-checkout'" style="padding: 8px;">
      <v-card class="header-card" elevation="1" color="white">
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="auto">
            <span class="text-subtitle-1 primary--text font-weight-medium">
              מערכת ניהול - משמחי לב
            </span>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <!-- Dashboard Grid -->
    <div class="dashboard-grid" style="margin-left: 10px; margin-right: 10px;" v-if="$route.name === 'home'">
      <v-row>
        <v-col v-for="(table, index) in tableListArray" :key="index" cols="12" sm="6" md="4" lg="3"
          style="padding-top: 0px !important; padding-bottom: 0px !important;">
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 8 : 2" :class="['dashboard-card', { 'on-hover': hover }]"
              @click="navigateToTable(table.routeName)">
              <div class="card-content">
                <v-icon size="48" :color="getIconColor(index)" class="card-icon">
                  {{ table.icon }}
                </v-icon>

                <h3 class="card-title">{{ table.name }}</h3>
              </div>

              <v-divider></v-divider>

              <v-card-actions class="card-actions">
                <v-btn text :color="getIconColor(index)" @click.stop="navigateToTable(table.routeName)">
                  <v-icon left>mdi-arrow-left</v-icon>
                  פתח
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </div>

    <!-- Router View with Transition -->
    <v-fade-transition mode="out-in">
      <router-view></router-view>
    </v-fade-transition>
  </div>
</template>
<script>
import TablesList from '@/components/TablesList'
import Auth from '@/services/auth.service.js'

export default {
  name: 'home',
  components: {
    TablesList,
  },
  data: () => ({
    loading: false,
    tableStats: {},
    tableListArray: [
      // {
      //   name: "ראשי",
      //   icon: "mdi-home",
      //   routeName: "home"
      // },
      {
        name: "משתמשים",
        icon: "mdi-account-group",
        routeName: "users"
      },
      {
        name: "לקוחות",
        icon: "mdi-account",
        routeName: "customers"
      },
      {
        name: "מלאי",
        icon: "mdi-warehouse",
        routeName: "inventory"

      },
      {
        name: "הזמנות",
        icon: "mdi-cart",
        routeName: "orders"

      },
      {
        name: "החלפה והחזרה",
        icon: "mdi-swap-horizontal",
        routeName: "exchanges"

      },
      {
        name: "דוחות",
        icon: "mdi-chart-bar",
        routeName: "reports"

      },
      {
        name: "קופה",
        icon: "mdi-cash-register",
        routeName: "cashier"

      },
      {
        name: "כניסה והדפסה",
        icon: "mdi-login",
        routeName: "entry"

      },
      {
        name: "לפני כניסה",
        icon: "mdi-door-open",
        routeName: "pre-entry"

      },
      {
        name: "הגדרות שעות חלוקה",
        icon: "mdi-clock-outline",
        routeName: "distribution"

      },
      {
        name: "מלאי טלפוני",
        icon: "mdi-phone",
        routeName: "phone-inventory"

      },
    ],
  }),
  methods: {
    getIconColor(index) {
      const colors = [
        'primary',
        'success',
        'info',
        'warning',
        'error',
        'purple',
        'indigo',
        'cyan',
        'teal',
        'orange',
        'deep-purple'
      ]
      return colors[index % colors.length]
    },
    navigateToTable(tableName) {
      console.log(1111);

      const table = this.tableListArray.find(t => t.routeName === tableName)
      console.log(table);
      console.log(tableName);

      if (table && table.routeName) {
        this.$router.push({ name: table.routeName })
      }
    },

  },
  created() {
    let role = Auth.getUserRole();
    if (role === "customers") {
      this.tableListArray = this.tableListArray.filter(table => table.name === "לקוחות")
    } else if (role === "reports") {
      this.tableListArray = this.tableListArray.filter(table => table.name === "דוחות")
    } else if (role === "products") {
      this.tableListArray = this.tableListArray.filter(table => table.name === "מלאי")
    } else if (role === "orders") {
      this.tableListArray = this.tableListArray.filter(table => table.name === "הזמנות")
    } else if (role === "exchanges") {
      this.tableListArray = this.tableListArray.filter(table => table.name === "החלפה והחזרה")
    } else if (role === "cashRegister") {
      this.tableListArray = this.tableListArray.filter(table => table.name === "קופה")
    } else if (role === "enterToShop") {
      this.tableListArray = this.tableListArray.filter(table => table.name === "לפני כניסה")
    } else if (role === "enterAndPrint") {
      this.tableListArray = this.tableListArray.filter(table => table.name === "כניסה והדפסה")
    } else if (role === "settings") {
      this.tableListArray = this.tableListArray.filter(table => table.name === "הגדרות שעות חלוקה")
    } else if (role === "phoneLimitStock") {
      this.tableListArray = this.tableListArray.filter(table => table.name === "מלאי טלפוני")
    } else if (role === "enterAndPrintAndExchanges") {
      this.tableListArray = this.tableListArray.filter(table => table.name === "כניסה והדפסה" || table.name === "החלפה והחזרה")
    } else if (role === "customersAndOrders") {
      this.tableListArray = this.tableListArray.filter(table => table.name === "לקוחות" || table.name === "הזמנות");
    } else if (role === "allExceptUsers") {
      this.tableListArray = this.tableListArray.filter(table => table.name !== "משתמשים")
    }
    // this table for everyone
    this.tableListArray.unshift({
      name: "ראשי",
      icon: "mdi-home",
      routeName: "home"

    })
  },
}
</script>
<style lang="scss" scoped>
.home-container {
  background: linear-gradient(135deg, #f5f7fa 0%, #e9ecef 100%);
  min-height: 100vh;
}

// .header-card {
//   background-color: white;
//   border-radius: 12px;
//   box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
// }

.dashboard-grid {
  .dashboard-card {
    border-radius: 12px;
    transition: all 0.3s ease;
    height: 80%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-top: 50px;

    &.on-hover {
      transform: translateY(-5px);
    }

    .card-content {
      padding: 1rem;
      text-align: center;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }

    .card-icon {
      margin-bottom: 0.5rem;
      transition: transform 0.3s ease;

      .on-hover & {
        transform: scale(1.1);
      }
    }

    .card-title {
      font-size: 1.25rem;
      font-weight: 500;
      color: #2d3748;
      margin: 0;
    }

    .card-stats {
      display: flex;
      justify-content: center;
      gap: 2rem;
      margin-top: auto;

      .stat-item {
        text-align: center;

        .stat-value {
          display: block;
          font-size: 1.5rem;
          font-weight: 600;
          color: #1a202c;
        }

        .stat-label {
          font-size: 0.875rem;
          color: #718096;
        }
      }
    }

    .card-actions {
      padding: 1rem;
      display: flex;
      justify-content: center;
    }
  }
}

/* RTL Support */
[dir="rtl"] {
  .v-btn__content {
    flex-direction: row-reverse;
  }

  .card-actions {
    .v-icon {
      transform: rotate(180deg);
    }
  }
}
</style>
