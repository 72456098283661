import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import Auth from '@/services/auth.service'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'users',
        name: 'users',
        component: () => import('@/components/users/UsersTable.vue')
      },
      {
        path: 'customers',
        name: 'customers',
        component: () => import('@/components/customers/CustomersTable.vue'),
      },
      {
        path: 'customers/:id',
        name: 'customer-details',
        component: () => import('@/components/customers/CustomerDetails.vue'),
        props: true
      },
      {
        path: 'inventory',
        name: 'inventory',
        component: () => import('@/components/products/ProductsTable.vue')
      },
      {
        path: 'orders',
        name: 'orders',
        component: () => import('@/components/orders/OrdersTable.vue')
      },
      {
        path: 'exchanges',
        name: 'exchanges',
        component: () => import('@/components/exchanges/ExchangesComp.vue')
      },
      {
        path: 'reports',
        name: 'reports',
        component: () => import('@/components/reports/ReportsMenuComp.vue')
      },
      {
        path: 'cashier',
        name: 'cashier',
        component: () => import('@/components/kupa/KupaComp1.vue')
      },
      {
        path: 'cashier/checkout',
        name: 'cashier-checkout',
        component: () => import('@/components/kupa/KupaComp2.vue')
      },
      {
        path: 'entry',
        name: 'entry',
        component: () => import('@/components/knisa/KnisaComp.vue')
      },
      {
        path: 'pre-entry',
        name: 'pre-entry',
        component: () => import('@/components/beforeKnisa/BeforeKnisaComp.vue')
      },
      {
        path: 'distribution',
        name: 'distribution',
        component: () => import('@/components/settings/SettingsComp.vue')
      },
      {
        path: 'phone-inventory',
        name: 'phone-inventory',
        component: () => import('@/components/phoneStock/PhoneStockComp.vue')
      },
      {
        path: 'account',
        name: 'account',
        component: () => import('@/components/account/UserAccount.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!Auth.checkAuth()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router