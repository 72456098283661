<template>
  <v-app>
    <!-- <v-app style="background: linear-gradient(180deg, #211f8a 0%, #635ddb 100%);"> -->

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>
import Auth from '@/services/auth.service.js'

export default {
  mounted() {
    setInterval(() => {
      let auth = Auth.checkAuth();
      if (!auth) {
        this.$router.replace({ name: "login" })
      }
    }, 2 * 60 * 1000);
  },

}
</script>
<style>
/* Customizes the scrollbar width */
::-webkit-scrollbar {
  width: 6px; /* Adjust the width of the scrollbar */
}

/* Customizes the scrollbar track (space to scroll) */
::-webkit-scrollbar-track {
  background-color: #FFFFFF; /* Sets the track color to white */
}

/* Customizes the scrollbar thumb (the draggable element) */
::-webkit-scrollbar-thumb {
  background-color: #0d2c6d; /* Sets the thumb color to blue */
  border-radius: 3px; /* Optional: Adds rounded corners to the thumb */
}


</style>