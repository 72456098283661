<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant="!isTablesListExpanded"
    :mini-variant-width="60"
    width="300"
    right
    app
    permanent
    class="drawer-gradient"
  >
    <div class="drawer-content" @mouseenter="handleMouseEnter">
      <!-- Header Section -->
      <div class="drawer-header">
        <v-list-item class="px-2">
          <v-list-item-action>
            <v-btn
              icon
              @click.stop="TOGGLE_TABLES_LIST"
              class="toggle-btn"
              color="primary"
            >
              <v-icon>{{ !isTablesListExpanded ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-slide-x-transition>
          <div v-if="isTablesListExpanded" class="user-profile pa-4">
            <div class="d-flex align-center justify-space-between">
              <div>
                <h3 class="text-h6 font-weight-bold primary--text mb-1">
                  {{ userName }}
                </h3>
                <p class="text-subtitle-2 grey--text text--darken-1">
                  {{ email }}
                </p>
              </div>
              <v-btn
                icon
                small
                color="primary"
                @click="logOut"
                class="logout-btn"
              >
                <v-icon>mdi-logout-variant</v-icon>
              </v-btn>
            </div>

            <v-btn
              rounded
              block
              elevation="2"
              color="primary"
              class="mt-4"
              :to="'/account'"
              exact
            >
              <v-icon left>mdi-account</v-icon>
              אזור אישי
            </v-btn>
          </div>
        </v-slide-x-transition>
      </div>

      <!-- Navigation Menu -->
      <v-list
        dense
        nav
        class="navigation-list"
      >
        <v-list-item
          v-for="item in tables"
          :key="item.name"
          :to="getRouteForTable(item.name)"
          exact
          class="nav-item"
          :class="{ 'mini-list-item': isCollapsed }"
        >
          <v-list-item-icon>
            <v-icon
              :color="$route.path === getRouteForTable(item.name) ? 'primary' : 'grey darken-1'"
            >
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              :class="{
                'font-weight-medium': $route.path === getRouteForTable(item.name),
                'grey--text text--darken-1': $route.path !== getRouteForTable(item.name)
              }"
            >
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action v-if="!isCollapsed && $route.path === getRouteForTable(item.name)">
            <v-icon color="primary" small>mdi-circle-small</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Auth from '@/services/auth.service.js'
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'TablesList',
  
  props: {
    tableListArray: {
      type: Array,
      required: true
    },
    isCollapsed: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    drawer: true,
    userName: "",
    email: "",
    tables: [],
    routeMap: {
      "משתמשים": "/users",
      "לקוחות": "/customers",
      "מלאי": "/inventory",
      "הזמנות": "/orders",
      "החלפה והחזרה": "/exchanges",
      "דוחות": "/reports",
      "קופה": "/cashier",
      "כניסה והדפסה": "/entry",
      "לפני כניסה": "/pre-entry",
      "הגדרות שעות חלוקה": "/distribution",
      "מלאי טלפוני": "/phone-inventory"
    }
  }),

  methods: {
    ...mapMutations(['TOGGLE_TABLES_LIST', 'SET_TABLES_LIST_EXPANDED']), // Import mutation
    getRouteForTable(name) {
      return this.routeMap[name] || '/';
    },

    getUserDetails() {
      this.userName = Auth.getUserName();
      this.email = Auth.getUserEmail();
    },

    logOut() {
      Auth.logout();
      this.$router.replace({ name: "login" });
    },
    handleMouseEnter() {
      if (!this.isTablesListExpanded) {
        this.TOGGLE_TABLES_LIST();
      }
    }
  },
  computed: {
    ...mapState(['isTablesListExpanded']), // Get Vuex state
  },
  watch: {
    isCollapsed(val) {
      this.drawer = !val;
    },
    
    tableListArray: {
      immediate: true,
      handler(newVal) {
        this.tables = newVal;
      }
    }
  },

  mounted() {
    this.getUserDetails();
  }
}
</script>

<style lang="scss" scoped>
.drawer-gradient {
  background: linear-gradient(145deg, #f8f9fa 0%, #e9ecef 100%);
  border: none !important;
}

.drawer-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.drawer-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.user-profile {
  transition: all 0.3s ease;
}

.navigation-list {
  flex-grow: 1;
  overflow-y: auto;
  padding-top: 8px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.03);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(94, 53, 177, 0.2);
    border-radius: 4px;

    &:hover {
      background: rgba(94, 53, 177, 0.3);
    }
  }
}

.nav-item {
  border-radius: 8px;
  margin: 4px 8px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background: rgba(94, 53, 177, 0.05) !important;
    transform: translateX(-4px);
  }

  &.v-list-item--active {
    background: rgba(94, 53, 177, 0.08) !important;

    &::before {
      opacity: 0;
    }
  }
}

.mini-list-item {
  justify-content: center;
  padding-left: 0;
  margin: 4px auto;
  width: 44px;

  &:hover {
    transform: scale(1.05);
  }
}

.toggle-btn {
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
}

.logout-btn {
  opacity: 0.8;
  transition: all 0.3s ease;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }
}

// RTL Support
.v-application--is-rtl {
  .nav-item {
    &:hover {
      transform: translateX(4px);
    }
  }

  .v-list-item__action:first-child {
    margin-right: 0;
    margin-left: 16px;
  }

  .v-list-item__icon:first-child {
    margin-right: 0;
    margin-left: 16px;
  }
}
</style>