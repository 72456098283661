<template>
  <div class="login-container">
    <div class="login-wrapper">
      <FormLogin />
    </div>
  </div>
</template>

<script>
import FormLogin from '@/components/auth/FormLogin.vue'
import Auth from '@/services/auth.service.js'

export default {
  name: 'LoginView',
  components: {
    FormLogin
  },
  mounted() {
    let auth = Auth.checkAuth();
    if (auth) {
      this.$router.replace({ name: "home"})
    }
  },
}
</script>

<style lang="scss" scoped>
.login-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #5e34e6 0%, #7c5cf5 100%);
  padding: 20px;
  box-sizing: border-box;
  animation: fadeIn 1s ease-out;
}

.login-wrapper {
  width: 100%;
  max-width: 500px;
  animation: slideUp 0.8s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>